import React from 'react';
import { Box, Flex, Heading, Image, Text } from 'rebass/styled-components';
import Interweave from 'interweave';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

export default function SingleGoodToKnowItem({ singleNews }) {
  console.log('singleNews BOOM', singleNews);
  return (
    <Flex variant={'wrapper'} flexDirection={'column'}>
      <Box width={[1 / 2, 1 / 3, 1 / 3]} maxHeight={'320px'}>
        <Image src={singleNews.imageSrc} />
      </Box>
      <Flex variant={'singlePageContent'}>
        <Box mx={50} my={20}>
          <Heading
            fontFamily={'Oswald'}
            color="text"
            fontWeight={'bold'}
            fontSize={'35px'}
            marginBottom={'10px'}
          >
            {singleNews.title}
          </Heading>
          <Text>{dayjs(singleNews.createdAt.unix).format('DD-MM-YYYY')}</Text>
          <Box className={'db-text'}>
            <Interweave content={singleNews.text} />
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

SingleGoodToKnowItem.propTypes = {
  slug: PropTypes.string,
  singleNews: PropTypes.string,
};

SingleGoodToKnowItem.defaultProps = {
  slug: null,
  singleNews: null,
};
