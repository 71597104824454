import React from 'react';
import { useQuery } from '@apollo/client';
import { navigate } from 'gatsby-link';
import PropTypes from 'prop-types';
import getGoodToKnowBySlug from '../../../graphql/queries/getGoodToKnowBySlug.graphql';
import Layout from '../../../components/Layout';
import Loader from '../../../components/Loader';
import SingleGoodToKnowItem from '../../../components/SingleGoodToKnowItem';

export default function SingleNewsPage({ slug }) {
  const { data, loading } = useQuery(getGoodToKnowBySlug, {
    variables: { slug },
  });
  const returnToMain = () => {
    if (typeof window !== 'undefined') {
      navigate('/');
    }
    return null;
  };
  if (!slug) {
    returnToMain();
  }
  if (loading && !data) {
    return <Loader />;
  }
  if (!loading && !data) {
    returnToMain();
  }
  const article = data.getGoodToKnowBySlug;
  return (
    <Layout>
      <SingleGoodToKnowItem slug={slug} singleNews={article} />
    </Layout>
  );
}

SingleNewsPage.propTypes = {
  slug: PropTypes.string,
};

SingleNewsPage.defaultProps = {
  slug: null,
};
